<template>
    <div class="modal secondary add_template" v-if="modelValue">
        <div class="modal_container add_types">
            <h3 class="sub_header mt-1 mb-4"> Select Template Type
                <button class="close_btn" @click="closeModal()"><i class="fas fa-times"></i></button>
            </h3>
            <ul>
                <li @click="createNew('email')">
                    <div class="card_item">
                        <img src="@/assets/images/mail.svg" alt=""> Email
                    </div>
                </li>
                <li @click="createNew('content')">
                    <div class="card_item">
                        <img src="@/assets/images/icon25.svg" alt=""> Content
                    </div>
                </li>
                <li @click="createNew('sms')">
                    <div class="card_item">
                        <img src="@/assets/images/sms.svg" alt=""> SMS
                    </div>
                </li>
                <li @click="createNew('multi')">
                    <div class="card_item">
                        <img src="@/assets/images/template.svg" alt=""> Multi Channel
                    </div>
                </li>
            </ul>
        </div>
    </div>

    <create-template v-model="createTemplate" :template-type="templateType" :categories="categoryList" :refresh-template="refreshTemplate" :refresh-category="refreshCategory" />
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { mapGetters } from 'vuex'

    const CreateTemplate = defineAsyncComponent(() => import('@/pages/templates/components/CreateTemplate'))

    export default {
        name: 'New Template',

        data() {
            return {
                createTemplate: false,
                templateType: '',
                categoryList: [],
            }
        },

        props: {
            modelValue: Boolean,
            refreshTemplate: Function,
        },

        emits: ['update:modelValue'],

        components: {
            CreateTemplate,
        },

        watch: {
            modelValue (val) {
                if (val) {
                    const vm = this;

                    vm.createTemplate = false;
                    vm.templateType = '';
                }
            }
        },

        computed: {
            ...mapGetters({
                filterCategoryType: 'templateModule/FILTER_CATEGORY_TYPE',
            }),
        },

        methods: {
            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            createNew (el) {
                const vm = this;

                vm.templateType   = el;
                vm.categoryList   = vm.filterCategoryType(el);
                vm.createTemplate = true;
                vm.closeModal();
            },

            refreshCategory (el) {
                const vm = this;

                vm.categoryList   = vm.filterCategoryType(el);
            },
        }
    }
</script>

<style scoped>
    .add_template .add_types ul {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px;
    }

    .add_template .add_types ul li {
        padding: 10px;
        width: 150px;
    }

    .close_btn  {
        border: 0;
        background: rgba(50,55,59,.6);
        font-size: 12px;
        color: #fff;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        position: absolute;
        right: 15px;
        top: 15px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
    }
</style>
